import { Outlet } from 'react-router-dom';

const SubscriptionWrapper = () => {
    return (
        <>
          <Outlet/>
        </>
    )
};

export default SubscriptionWrapper; 
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import AuthProvider from './contexts/AuthContext';
import { CookiesProvider } from 'react-cookie';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const domain: any = process.env.REACT_APP_AUTH0_DOMAIN; 
const clientId: any = process.env.REACT_APP_AUTH0_CLIENT_ID; 

root.render(
    <Auth0Provider
    domain={domain}
    clientId={clientId}
    cacheLocation='localstorage'
    // useRefreshTokens={true}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: process.env. REACT_APP_AUTH0_AUDIENCE,
      // audience: 'https://api-dev.nftrends-ai.com/api',
      scope: 'openid profile email offline_access',
      // screen_hint: 'signup'
    }}
  >
        <BrowserRouter>
          <AuthProvider>
            <CookiesProvider defaultSetOptions={{ path: '/', sameSite: true, secure: false }}>
              <App />
            </CookiesProvider>
          </AuthProvider>
        </BrowserRouter>
    </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

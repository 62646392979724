import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export const useListenInternetConnection = () => {
    const [isOnline, setIsOnline] = useState(window.navigator.onLine);

    useEffect(() => {
      function handleOnlineStatus() {
        setIsOnline(true);
        toast.success('You are reconnected to internet.', {
            hideProgressBar: true,
        });
      }
  
      function handleOfflineStatus() {
        toast.error('You are no longer connected to internet.', {
            hideProgressBar: true,
        });
        setIsOnline(false);
      }
  
      window.addEventListener('online', handleOnlineStatus);
      window.addEventListener('offline', handleOfflineStatus);
  
      return () => {
        window.removeEventListener('online', handleOnlineStatus);
        window.removeEventListener('offline', handleOfflineStatus);
      };
    }, []);

    return {
        isOnline, 
    }
}
import { Outlet } from 'react-router-dom';
import AdminLayout from '../../layouts/AdminLayout';
import AdminProvider from '../../contexts/AdminContext';

const Dashboard = () => {
  return (
    <AdminProvider>
      <AdminLayout>
        <Outlet />
      </AdminLayout>
    </AdminProvider>
  );
};

export default Dashboard;

import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios';

interface RequestOptions extends AxiosRequestConfig {
  retry?: number;
}

interface ApiResponse<T> extends AxiosResponse {
  data: T;
}

interface ApiError {
  message: string;
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  // timeout: 5000,
  headers: {
    common: {
      'Content-Type': 'application/json',
    },
  },
  // withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config: any) => {
    const token = sessionStorage.getItem('token');
    if (token) {
       config.headers.Authorization = `Bearer ${token}`;
    }  
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response: ApiResponse<any>) => {
    return response;
  },
  (error) => {
/*     if(error?.response?.data?.code === 2002) {
      localStorage.removeItem('token'); 
    } */

   const errorMessage =
   error.response?.data?.message || error.message || 'Something went wrong';
   const errorData = {
    code: error?.response?.data?.code, 
    message: errorMessage, 
   }
   const errorResult = error?.response?.data?.code ? errorData : errorMessage; 
    return Promise.reject(errorResult);
  }
);

export const api = {
    get: async <T>(
      url: string,
      params: any = {},
      options: RequestOptions = {}
    ): Promise<T> => {
      try {
        const response = await axiosInstance.get<T>(url, { params, ...options });
        return response.data;
      } catch (error: any) {
        if(error?.code) {
          throw error;
        } else {
          throw new Error(error);
        }       
      }
    },
    post: async <T>(
      url: string,
      data: any = {},
      options: RequestOptions = {}
    ): Promise<T> => {
      try {
        const response = await axiosInstance.post<T>(url, data, options);
        return response.data;
      } catch (error: any) {
        if(error?.code) {
          throw error;
        } else {
          throw new Error(error);
        }      
      }
    },
    put: async <T>(
      url: string,
      data: any = {},
      options: RequestOptions = {}
    ): Promise<T> => {
      try {
        const response = await axiosInstance.put<T>(url, data, options);
        return response.data;
      } catch (error: any) {
        if(error?.code) {
          throw error;
        } else {
          throw new Error(error);
        }      
      }
    },
    delete: async <T>(
      url: string,
      params: any = {},
      options: RequestOptions = {}
    ): Promise<T> => {
      try {
        const response = await axiosInstance.delete<T>(url, {params,  ...options});
        return response.data;
      } catch (error: any) {
        if(error?.code) {
          throw error;
        } else {
          throw new Error(error);
        }      
      }
    },
  };
  
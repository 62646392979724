import { createContext, useContext, useState } from 'react';
import { handleError } from '../common/utils/handleError';
import InventoryService from '../api/services/inventoryService';

interface Props {
    children: JSX.Element, 
}

const AdminContext = createContext<AdminContextInterface>({
    getPeople: (page: number = 0, searchValue?:any) => null, 
    getAllInventory: (page: number = 0, searchValue?:string, isSort?: boolean, typeSorted?: string, size?: string) => null, 
    getSearchInventory: (page: number = 0, searchValue?:string) => null, 
    listPeople: [], 
    loadingPeople: false,
    loadingInventory: false, 
    metaDataUsers: null,
    currentEmployee: null,
    newUser: null,
    setNewUser: (user: User) => null,  
    setCurrentEmployee: (user: User) => null,  
    searchParamFormattedData: null,
    setSearchParamFormattedData: (data: any) => null,
    inventoryList: [],
    searchInventoryList: [],
    sortedInventoryList: [],
    inventoryMeta: null,
    currentInventory: null,
    setCurrentInventory: (inventory: Inventory) => null,
    searchParam: '',
    setSearchParam: (value: string) => null,
    searchParamCompany: '',
    setSearchParamCompany: (value: string) => null,
    empty: '',
    setEmpty: (value: string) => null,
    searchParamInventory: '',
    setSearchParamInventory: (value: string) => null,
    emptyInventory: '',
    setEmptyInventory: (value: string) => null,
    listCheckedOptions: {},
    setListCheckedOptions: (options: CheckedOptionsI) => null, 
    filtersData: null,
    setFiltersData: (filters:any ) => null, 
    getInventory: (id: string) => null, 
});

export function useAdminContext() {
    return useContext(AdminContext);
}

const AdminProvider = ({ children }: Props) => {
    const [listPeople, setListPeople] = useState<UserAdmin[]>([]); 
    const [inventoryList, setInventoryList] = useState<Inventory[]>([]); 
    const [searchInventoryList, setSearchInventoryList] = useState<Inventory[]>([]); 
    const [sortedInventoryList, setSortedInventoryList] = useState<Inventory[]>([]); 
    const [loadingPeople, setLoadingPeople] = useState(false);
    const [loadingInventory, setLoadingInventory] = useState(false); 
    const [currentEmployee, setCurrentEmployee] = useState<User | null>(null);
    const [newUser, setNewUser] = useState<User | null>(null);
    const [currentInventory, setCurrentInventory] = useState<Inventory | null>(null);
    const [metaDataUsers, setMetaDataUsers] = useState<ResponseDataPaginationI | null>(null);
    const [searchParam, setSearchParam] = useState('');
    const [searchParamFormattedData, setSearchParamFormattedData] = useState(null); 
    const [empty, setEmpty] = useState(''); 
    const [searchParamInventory, setSearchParamInventory] = useState('');
    const [searchParamCompany, setSearchParamCompany] = useState('');
    const [emptyInventory, setEmptyInventory] = useState(''); 
    const [inventoryMeta, setInventoryMeta] = useState<ResponseDataPaginationI | null>(
        null
      );
    const [listCheckedOptions, setListCheckedOptions] = useState<CheckedOptionsI>({});  
    const [filtersData, setFiltersData] = useState<any>(null);

    const getPeople = async (page: number = 0, searchValue?:any) => {
        try {
            setLoadingPeople(true);
            const data = searchValue || filtersData; 
            const result = await InventoryService.getUsers(page, data); 
             if(result) {
                if (page === 0) {
                    setListPeople(result.content);
                  } else {
                    setListPeople([...listPeople, ...result.content]);
                  }
                setMetaDataUsers(result); 
            } 
        } catch (error:any) {
            handleError(error.message); 
        } finally {
            setLoadingPeople(false); 
        }
    }

    const getAllInventory = async (page: number = 0, searchValue?: string, isSort: boolean = false, typeSorted: string = '', size: string = '100') => {
        try {
            setLoadingPeople(true);
            const result = await InventoryService.getAllInventory(page, searchValue, isSort, typeSorted, size); 
            if(result) {
                if (page === 0) {
                    if(isSort) {
                        setSortedInventoryList(result.content); 
                    } else {
                        setInventoryList(result.content);
                    }
                  } else {
                    setInventoryList([...inventoryList, ...result.content]);
                }
            } 
            setInventoryMeta(result); 
        } catch (error:any) {
            handleError(error.message); 
        } finally {
            setLoadingPeople(false); 
        }
    }

    const getSearchInventory = async (page: number = 0, searchValue?: string) => {
        try {
            setLoadingInventory(true);
            const result = await InventoryService.getAllInventory(page, searchValue, false, '', '10'); 
            if(result) {
                setSearchInventoryList(result.content);
            }
        } catch (error:any) {
            handleError(error.message); 
        } finally {
            setLoadingInventory(false); 
        }
    }

    const getInventory = async (id: string) => {
        try {
            const result = await InventoryService.getInventoryById(id); 
            if(result) {
                setCurrentInventory(result);
            }
        } catch (error: any) {
            handleError(error.message); 
        }
    }

    const value = {
        getPeople,
        listPeople,
        loadingPeople, 
        metaDataUsers,
        currentEmployee,
        setCurrentEmployee,
        inventoryList, 
        getAllInventory,
        inventoryMeta,
        currentInventory,
        setCurrentInventory,
        sortedInventoryList,
        newUser,
        setNewUser,
        searchParam,
        setSearchParam,
        empty,
        setEmpty,
        searchParamInventory,
        setSearchParamInventory,
        searchParamFormattedData,
        setSearchParamFormattedData, 
        emptyInventory,
        setEmptyInventory,
        listCheckedOptions,
        setListCheckedOptions,
        filtersData,
        setFiltersData,
        getInventory,
        loadingInventory,
        searchParamCompany,
        setSearchParamCompany,
        searchInventoryList,
        getSearchInventory
    };
    return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>;
} 

export default AdminProvider; 
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const handleSuccessNotify = (message: string) => {
    toast.success(message, {
      hideProgressBar: true,
      autoClose: 5000,
    });
}

export const handleSuccessNotifyWithRedirect = (message: string, handleClick: () => void) => {
  toast.success(message, {
    hideProgressBar: true,
    autoClose: 10000,
    onClick: () => {
      handleClick(); 
    },  
  });
}
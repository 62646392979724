import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import EN from './i18next/en';

i18n
	.use(initReactI18next)
	.init({
		lng: 'en',
		fallbackLng: 'en',
		debug: process.env.NODE_ENV === 'development',

		interpolation: {
			escapeValue: false,
		},

		resources: {
			en: { translation: EN },
		},
	});

export default i18n;
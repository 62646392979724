import { useEffect, useState } from 'react';
import { useAppContext } from '../contexts/AppContext';
import { getHumanizeFormatDateOne, isGracePeriodCheck } from '../common/utils/functions';

export function useGetSubscriptionStatus() {
    const [showSubscriptionInformer, setShowSubscriptionInformer] = useState(false); 
    const [reminderText, setReminderText] = useState(''); 
    const {subscriptionData} = useAppContext();

    const reminderTextGracePeriod = `This is a reminder that your membership with NFTrends expired on ${getHumanizeFormatDateOne(subscriptionData?.end_date ?? '')} and you are now within your membership grace period. Please renew your membership to continue having access to your DPPs. It couldn’t be easier – just click here to renew!`; 
    const reminderTextBase = `This is a reminder that your membership with NFTrends is expiring on ${getHumanizeFormatDateOne(subscriptionData?.end_date ?? '')}. Please renew your membership to continue having access to your DPPs. It couldn’t be easier – just click here to renew!`; 

    useEffect(() => {
        if(subscriptionData?.status === 'CANCELED') {
            setShowSubscriptionInformer(true); 
            const text = isGracePeriodCheck(subscriptionData.end_date) ? reminderTextGracePeriod : reminderTextBase; 
            setReminderText(text); 
        } else if(subscriptionData?.status === 'ACTIVE' && showSubscriptionInformer) {
            setShowSubscriptionInformer(false); 
        }
    }, [subscriptionData?.status]); 

    return {
        showSubscriptionInformer,
        setShowSubscriptionInformer,
        reminderText
    }
}
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RightArrow } from '../../assets/images/arrow-right-black-base.svg';
import {
  anchors,
  carousel_images,
  follow_us_icons,
  partners_icons,
  teamBussinesData,
  teamTeachnologyData,
  updatedNewsData,
} from './data';
import { ContactUsForm, FollowUsMobile, InfoSection, NabvarMainLg, NavbarMainMobile, NewsSectionMainMobile, TeamCard, VideoSection, VideoSectionBase } from './MainComponents';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { paths, Roles } from '../../common/constants';
import { handleMailtoClick, isValidRangeHandler, scrollToTop } from '../../common/utils/functions';
import FadeCarousel from '../../components/Carousel/FadeCarousel';
import { useAuthContext } from '../../contexts/AuthContext';
import StickyBox from 'react-sticky-box';
import { ArrowLeftCustom } from '../../components/CustomIcons';
import { ResponsiveStickyBox } from '../../components/StickyBox/ResponsiveStikyBox';
import { useWindowSize } from 'usehooks-ts';
import clsx from 'clsx';

function LandingMain() {
  const { t } = useTranslation();
  const navigate = useNavigate(); 
  const [directionScroll, setDirectionScroll] = useState<'up' | 'down'>('up');
  const [isVisibleScrollToTop, setIsVisibleScrollToTop] = useState(false); 
  const { login, isAuth, userData, isCheckedRNAuth0 } = useAuthContext();
  const scrollYPosition = useRef<any>(0);
  const {width} = useWindowSize(); 
  const isDesktop = width > 1024; 
  const { hash } = useLocation();
  let oldScrollY = 0;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const isRNApp = window?.ReactNativeWebView; 

  const handleLogin = (type: 'signup' | 'login') => {
    if(type === 'signup') {
      navigate('/onboarding'); 
      return;
    }
    // login();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (window?.ReactNativeWebView) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window?.ReactNativeWebView?.postMessage('login');
    } else {
      login(type);
    }
  };

  const handleClickFollowUs = () => {
    window.open('https://www.instagram.com/nftrends.ai/', '_blank')
  }

  useEffect(() => {
    if (userData?.userRole) {
      const url =
        userData?.userRole === Roles.UBER_ADMIN
          ? '/admin/people'
          : '/dashboard/items';
      navigate(url);
    }
  }, [userData?.userRole]);


  const controlDirection = () => {
    const isValidRange = isValidRangeHandler(window.scrollY, oldScrollY); 
    const shouldBeVisible = window.pageYOffset > 1200;
    if (shouldBeVisible !== isVisibleScrollToTop) {
      setIsVisibleScrollToTop(shouldBeVisible);
    }


    if (window.scrollY > 0 && window.scrollY > oldScrollY && isValidRange) {
      setDirectionScroll('down');
    }  else {
      setDirectionScroll('up');
    }
    oldScrollY = window.scrollY;
    scrollYPosition.current = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', controlDirection);

    return () => {
      window.removeEventListener('scroll', controlDirection);
    };
  }, [isVisibleScrollToTop]); 

  useEffect(() => {
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'auto' });
      }
    }
  }, []); 

  if (isAuth && !isRNApp) {
    const url =
      userData?.userRole === Roles.UBER_ADMIN
        ? '/admin/people'
        : '/dashboard/items';
      console.log('navigate to as web app user', url); 
    return <Navigate to={url} replace />;
  }

  if (userData?.userRole && isAuth && isRNApp && isCheckedRNAuth0) {
    const url =
      userData?.userRole === Roles.UBER_ADMIN
        ? '/admin/people'
        : '/dashboard/items';
      console.log('navigate to as rn user', url); 
    return <Navigate to={url} replace />;
  }


  if((isRNApp && !isCheckedRNAuth0) || (isRNApp && !isAuth && !isCheckedRNAuth0)) {
     return <div className='w-full h-screen bg-[#2440D6]'>1</div>
  }

  return (
    <div className='raleway-400'>
      {isVisibleScrollToTop && <button onClick={scrollToTop} className='fixed bottom-[20px] right-[20px] w-[45px] h-[45px] rounded-full z-[200] bg-[#626262] flex items-center justify-center '>
          <div className='rotate-90 translate-x-[2px]'>
            <ArrowLeftCustom width={'16'} height={'16'} color={'white'}/>
          </div>
        </button>}
      <div className='w-full h-full lg:h-screen relative'>
        <div className='w-full flex justify-center'>
          <NabvarMainLg directionScroll={directionScroll} handleLogin={handleLogin}/>
          <NavbarMainMobile directionScroll={directionScroll} handleLogin={handleLogin}/>
        </div>
        
        {/* <img src={carousel_images[0]} className='w-full h-full lg:hidden object-cover'/> */}
        
        <FadeCarousel images={carousel_images}/>
      
        
        <div className='absolute top-[325px] lg:top-auto lg:bottom-[31px] lg:left-[40px] z-50 mx-[12px]'>
          <div className='text-white rounded-[16px] bg-[#1A1A1A]/25 backdrop-blur-lg w-full lg:w-[580px] p-[16px] lg:p-[40px]'>
            <div className='text-[32px] lg:text-[56px] raleway-600 leading-[44px] lg:leading-[78px]'>
              {t('main.main_card_title')}
            </div>
            <div className='text-[16px] lg:text-[20px] raleway-500 leading-[24px] lg:leading-[30px] py-[12px] lg:py-[16px]'>
              {t('main.main_card_text')}
            </div>
            <div  className='w-full flex justify-between'>
              <a href={`#${anchors.ABOUT}`} className='rounded-[40px] bg-white text-[#1A1A1A] text-[15px] raleway-600 py-[12px] px-[20px] flex space-x-[8px]'>
                {' '}
                 {t('main.discover_more')}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div  className='flex flex-col lg:flex-row w-full px-[12px] lg:px-[40px] pt-[200px] pb-[50px] lg:pt-unset lg:py-[100px] relative text-[#1A1A1A]'>
        <div className='absolute top-[20px]' id={anchors.ABOUT}></div>
        <div className='w-full lg:w-1/3 text-[32px] lg:text-[50px] raleway-600'>About</div>
        <div className='w-full lg:w-2/3 space-y-[20px] raleway-400  lg:space-y-[60px] text-[16px] lg:text-[26px] leading-[30px] lg:leading-[39px]'>
          <div>{t('main.about_1')}</div>
          <div>{t('main.about_2')}</div>
        </div>
      </div>

      <VideoSection srcUrl='https://storage.googleapis.com/dev-nftrends-media/new-landing/NF%20Trends%20-%20Promo%20-%20H264.mp4'/>

      {/* Our solutions section */}

      <div>
        <div id={anchors.SOLUTIONS} className='px-[12px] lg:px-[40px] w-full lg:w-2/3 text-[32px] lg:text-[50px] raleway-600 leading-[44px] lg:leading-[70px] pt-[52px] lg:pt-auto'>
          {t('main.solutions_title')}
        </div>
        <VideoSectionBase srcUrl='https://storage.googleapis.com/dev-nftrends-media/new-landing/NFTrends_Demo_V2_H264.mp4'/>
        <div className='px-[12px] lg:px-0'>
        <ResponsiveStickyBox offsetTop={0} offsetBottom={20}>
          <div className='w-full flex flex-col lg:flex-row mb-[32px] lg:mb-[1px]'>
            <div className='w-full lg:w-1/2'>
              <img
                className='w-full'
                src='https://storage.googleapis.com/dev-nftrends-media/new-landing/solutions-1.png'
                alt='image'
              />
            </div>
            <div className='w-full lg:w-1/2 bg-[#F2F2F2] px-[12px] lg:px-[40px] pt-[40px]'>
              <div className='flex space-x-[16px] items-center'>
             {/*    <div className='w-[40px] h-[40px] rounded-[50%] bg-[#000] text-white flex items-center justify-center text-[16px] raleway-600'>
                  1
                </div> */}
                <div className='w-full lg:w-none text-[26px] leading-[34px]  lg:text-[34px] raleway-600 lg:leading-[47px]'>
                  {t('main.solutions_title_1')}
                </div>
              </div>
              <div className='text-[16px] leading-[24px] lg:text-[18px] raleway-500 lg:leading-[27px] space-y-[10px] lg:space-y-[30px] pb-[16px] pt-[18px] lg:pt-[24px]'>
                <p>{t('main.solutions_text_1_1')}</p>
                <p>{t('main.solutions_text_1_2')}</p>
                <p>{t('main.solutions_text_1_3')}</p>
                <p>{t('main.solutions_text_1_4')}</p>
              </div>
            </div>
          </div>
          </ResponsiveStickyBox>
          <ResponsiveStickyBox offsetTop={0} offsetBottom={20}>
          <div className='w-full flex flex-col lg:flex-row mb-[32px] lg:mb-[1px]'>
            <div className='w-full lg:w-1/2'>
              <img
                className='w-full'
                src='https://storage.googleapis.com/dev-nftrends-media/new-landing/solutions-2.png'
                alt='image'
              />
            </div>
            <div className='w-full lg:w-1/2 bg-[#F2F2F2] px-[12px] lg:px-[40px] pt-[40px]'>
              <div className='flex space-x-[16px] items-center'>
        {/*         <div className='w-[40px] h-[40px] rounded-[50%] bg-[#000] text-white flex items-center justify-center text-[16px] raleway-600'>
                  2
                </div> */}
                <div className='w-full text-[26px] leading-[34px] lg:w-none lg:text-[34px] raleway-600 lg:leading-[47px]'>
                  {t('main.solutions_title_2')}
                </div>
              </div>
              <div className='text-[16px] leading-[24px] lg:text-[18px] raleway-500 lg:leading-[27px] space-y-[10px] pt-[16px] pb-[16px] lg:pt-[24px]'>
                <p>{t('main.solutions_text_2_1')}</p>
                <p>{t('main.solutions_text_2_2')}</p>
                <p>{t('main.solutions_text_2_3')}</p>
              </div>
            </div>
          </div>
          </ResponsiveStickyBox>
          <ResponsiveStickyBox offsetTop={0} offsetBottom={20}>
          <div className='w-full flex flex-col lg:flex-row mb-[32px] lg:mb-auto'>
            <div className='w-full lg:w-1/2'>
              <img
                className='w-full'
                src='https://storage.googleapis.com/dev-nftrends-media/new-landing/solutions-3.png'
                alt='image'
              />
            </div>
            <div className='w-full lg:w-1/2 bg-[#F2F2F2] px-[12px] lg:px-[40px] pt-[40px]'>
              <div className='flex space-x-[16px] items-center'>
  {/*               <div className='w-[40px] h-[40px] rounded-[50%] bg-[#000] text-white flex items-center justify-center text-[16px] raleway-600'>
                  3
                </div> */}
                <div className='w-full lg:w-none text-[26px] leading-[34px] lg:text-[34px] raleway-600 lg:leading-[47px]'>
                  {t('main.solutions_title_3')}
                </div>
              </div>
              <div className='text-[16px] leading-[24px] lg:text-[18px] raleway-500 lg:leading-[27px] space-y-[10px] lg:space-y-[30px] pt-[16px] pb-[16px] lg:pt-[24px]'>
                <p>{t('main.solutions_text_3_1')}</p>
                <p>{t('main.solutions_text_3_2')}</p>
                <p>{t('main.solutions_text_3_3')}</p>
              </div>
            </div>
          </div>
          </ResponsiveStickyBox>
{/*           <StickyBox offsetTop={120} offsetBottom={-120}>
            <div className='w-full h-[400px]'></div>
          </StickyBox>  */}
        </div>
      </div>

      {/* Featured story */}

      <div className='relative raleway-600'>
        <div className='text-[32px] lg:text-[50px] text-white absolute top-[40px] left-[12px] lg:left-[40px]'>
          {t('main.feature_title')}
        </div>
        <img
          className='hidden lg:block w-full lg:h-auto object-cover'
          src='https://storage.googleapis.com/dev-nftrends-media/new-landing/featured-bg.png'
          alt='image'
        />

        <img
          className='lg:hidden w-full h-full object-contain'
          src='https://storage.googleapis.com/dev-nftrends-media/new-landing/OUCHHH%2011-2.png'
          alt='image'
        />

        <div className='absolute top-[280px] lg:top-auto lg:bottom-[31px] lg:right-[40px] mx-[12px] lg:mx-auto z-40'>
          <div className='text-white rounded-[16px] bg-[#1A1A1A]/25 backdrop-blur-lg lg:w-[580px] p-[16px] lg:p-[40px]'>
            <div className='text-[14px] raleway-600 leading-[21px]'>
              01/10/2024
            </div>
            <div className='text-[22px] lg:text-[34px] raleway-600 leading-[30px] lg:leading-[47px] py-[16px]'>
              {t('main.feature_text')}
            </div>
            <div className='w-full flex justify-between'>
              <a href={'https://storage.googleapis.com/dev-nftrends-media/new-landing/NFtrends%20X%20OUCHHH.pdf'} target="_blank" className='rounded-[40px] bg-white text-[#1A1A1A] text-[15px] raleway-600 py-[12px] px-[20px] flex space-x-[8px]' rel="noreferrer">
                {' '}
                <span>{t('main.discover_more')} </span>
                <RightArrow />
              </a>
              <a href={`#${anchors.NEWS}`} className='rounded-[40px] border border-white text-white text-[15px] raleway-600 py-[12px] px-[20px]'>
                {t('main.see_all_news')}
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Teams section */}

      <div id={anchors.TEAM} className='pt-[150px] lg:pt-[100px] relative'>
        <div className='w-full flex flex-col items-center pb-[10px] lg:pb-[32px] px-[12px]'>
          <div className='text-[32px] lg:text-[50px] raleway-600 leading-[44px] lg:leading-[70px]'>
            {t('main.team_title')}
          </div>
        </div>
        <div className='px-[12px] lg:px-[40px] text-[#1A1A1A] pb-[44px] lg:pb-[80px]'>
          <div className='text-[16px] lg:text-[20px] raleway-600 pb-[16px] text-center'>
            {t('main.team_title_1')}
          </div>
          <div className='w-full grid grid-cols-1 lg:grid-cols-4 gap-4 lg:gap-8'>
            {teamBussinesData.map((item, idx) => (
              <TeamCard key={idx} item={item} />
            ))}
          </div>
        </div>
        <div className='px-[12px] lg:px-[40px] text-[#1A1A1A] pb-[55px] lg:pb-[100px]'>
          <div className='text-[16px] raleway-600 pb-[16px] text-center'>
            {t('main.team_title_2')}
          </div>
          <div className='w-full grid grid-cols-1 lg:grid-cols-4 gap-4 lg:gap-8'>
            {teamTeachnologyData.map((item, idx) => (
              <TeamCard key={idx} item={item} />
            ))}
          </div>
        </div>
        <div className='absolute bottom-0 right-0 hidden lg:block'>
          <img
            src='https://storage.googleapis.com/dev-nftrends-media/new-landing/logo_background.svg'
            alt='image'
          />
        </div>
      </div>

      {/* common-info */}

      <div className='bg-[#01155C] text-[#fff] py-[52px] lg:py-[100px] px-[12px] lg:px-[40px]'>
        <div className='text-[32px] lg:text-[50px] raleway-600 leading-[44px] lg:leading-[70px] pb-[56px]'>
          Empowering every stakeholder <br /> in the art ecosystem
        </div>
        <InfoSection />
      </div>

      {/* news section */}

      <div id={anchors.NEWS} className='px-[12px] py-[52px] lg:py-[100px] lg:px-[40px]'>
        <div className='text-[32px] lg:text-[50px] raleway-600 leading-[44px] lg:leading-[70px] pb-[24px]'>
          {t('main.news_title')}
        </div>
        <div className='hidden lg:grid grid-cols-1 lg:grid-cols-3 gap-8'>
          {updatedNewsData.map((item) => (
            <div key={item.id} className='cursor-pointer' onClick={() => {
              if(item.url_redirect) {
                window.open(item.url_redirect, '_blank')
              }

            }}>
              <div>
                <img src={item.url} alt='image' className='h-[280px] object-cover'/>
              </div>
              <div className='pt-[16px] text-[#808080] raleway-500 text-[16px]'>
                {item.date}
              </div>
              <div className='text-[#1A1A1A] text-[26px] raleway-600 leading-[36px] pt-[12px] line-clamp-3'>
                {item.title}
              </div>
            </div>
          ))}
        </div>
        <NewsSectionMainMobile/>
      </div>

      {/* follow us */}

      <div className='px-[12px] pt-[60px] pb-[100px] lg:px-[40px] lg:py-[100px] bg-[#000]'>
{/*         <div>
          <div onClick={handleClickFollowUs} className='flex items-center justify-center text-[#fff] text-[26px] lg:text-[40px] raleway-600 leading-[36px] lg:leading-[56px] pb-[16px] lg:pb-[24px] cursor-pointer'>
            <span className='mr-[24px]'>Follow us</span>
            <InstagramIcon/>
            <span>NFtrends.ai</span>
          </div>
          <div className='hidden lg:grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8'>
            {follow_us_icons.map((url) => (
              <img className='w-full cursor-pointer' key={url} src={url} alt='image' onClick={handleClickFollowUs}/>
            ))}
          </div>
          <FollowUsMobile/>
        </div> */}
        <div className='w-full text-center'>
          <div className='w-full text-center text-[#fff] text-[32px] lg:text-[40px] raleway-600 leading-[44px] lg:leading-[56px] pb-[84px]'>
            {t('main.partners_title')}
          </div>
          <div className='w-full grid grid-cols-2 lg:grid-cols-8 gap-8 lg:gap-8'>
            {partners_icons.slice(0, 8).map((el, idx) => (
              <div key={el.url} className={clsx('w-full h-[67px] flex items-center justify-center')}>
                <img
                  src={el.url}
                  alt='image'
                  onClick={() => {
                    if(el.partner_url) {
                      window.open(el.partner_url, '_target')
                    }
                  }}
                  className={clsx(' w-auto object-contain cursor-pointer', {
                    'h-[130px]': idx === 4,
                    'h-[67px]': idx !== 4
                  })}
                />
              </div>
            ))}
          </div>
          <div className='w-full flex justify-center items-center flex-wrap lg:space-y-4 space-y-4'>
            {partners_icons.slice(8).map((el) => (
              <div key={el.url} className='p-4 w-1/2 pt-[20px] lg:w-[150px] h-[67px] flex justify-center'>
                <img
                  src={el.url}
                  alt='image'
                  onClick={() => {
                    if(el.partner_url) {
                      window.open(el.partner_url, '_target')
                    }
                  }}
                  className='h-[67px] w-auto object-contain cursor-pointer'
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Book free call */}

      <div className='flex items-center justify-center w-full h-[565px] lg:h-[420px]' style={{ backgroundImage: isDesktop ? 'url(https://storage.googleapis.com/dev-nftrends-media/new-landing/book_free_call_2.png)' : 'url(https://storage.googleapis.com/dev-nftrends-media/new-landing/bg-call-3.svg)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}>
      {/* <img src="https://storage.googleapis.com/dev-nftrends-media/new-landing/book-free-call.svg" alt='image' className='w-full h-[565]'/> */}
        <div className='space-y-[24px] text-center'>
          <div className='text-[32px] lg:text-[50px] text-[#fff] leading-[44px] lg:leading-[70px] raleway-600 pb-[24px]'>{t('main.book_title')}</div>
          <a href={`#${anchors.CONTACT}`} className='rounded-[40px] bg-[#fff] text-[18px] raleway-600 text-[#1A1A1A] py-[16px] px-[32px]'>
            Book now
          </a>
        </div>
      </div>

      {/* contact us */}

      <div id={anchors.CONTACT} className='px-[12px] py-[52px] lg:px-[40px] lg:py-[100px] flex flex-col lg:flex-row text-[#1A1A1A]'>
        <div className='w-full text-center lg:text-left lg:w-[40%] space-y-[24px] pb-4 lg:pb-0'>
          <div className='text-[32px] lg:text-[50px] raleway-600 leading-[44px] lg:leading-[70px]'>
            {t('main.contact_title')}
          </div>
          <div className='text-[18px] raleway-500 leading-[27px]'>
            {t('main.contact_text')}
          </div>
        </div>
        <div className='w-full lg:w-[60%]'>
          <ContactUsForm />
        </div>
      </div>

      {/* footer */}
      <div className='px-[12px] lg:px-[40px] py-[32px] bg-[#F2F2F2] raleway-500 text-[#1A1A1A]'>
        <div className='text-[14px] pb-[16px]'>HelloNFtrends@nftrends.ai</div>
        <div className='text-[14px]'>
          Al Khatem TowerADGM Square, Al Maryah Island Abu Dhabi, UAE
        </div>
        <div className='pt-[32px] text-[#808080] text-[14px]'>
          <div className='flex space-x-[20px]'>
            <a href="https://www.youtube.com/@NFtrendsAI" target="_blank" rel="noreferrer">
              <img
                src='https://storage.googleapis.com/dev-nftrends-media/new-landing/social-1.svg'
                alt='image'
              />
            </a>  
            <a href="https://www.instagram.com/nftrends.ai/" target="_blank" rel="noreferrer">
              <img
                src='https://storage.googleapis.com/dev-nftrends-media/new-landing/social-4.svg'
                alt='image'
              /> 
            </a>
            <a href="https://www.linkedin.com/company/nftrends/" target="_blank" rel="noreferrer">
              <img
                src='https://storage.googleapis.com/dev-nftrends-media/new-landing/social-3.svg'
                alt='image'
              />
            </a>
  
          </div>
        </div>
        <div className='lg:pt-[32px] flex flex-col lg:flex-row w-full justify-between lg:items-center '>
          <div className='flex flex-col lg:flex-row items-start lg:items-center'>
            <ul className='flex flex-wrap w-full lg:justify-center md:justify-start md:flex-nowrap text-[14px] raleway-500 md:space-x-[12px] lg:space-x-[32px] pt-[32px] lg:pt-0 text-[#1A1A1A]'>
              <li className='mr-2 md:mr-0'>
                <Link to={paths.TERMS_OF_USE} target='_blank' rel='noreferrer'>
                  Terms and conditions
                </Link>
              </li>
              <li className='mr-2 md:mr-0'>
                <Link to={paths.POLICY} target='_blank' rel='noreferrer'>
                  Privacy policy
                </Link>
              </li>
              <li className='mt-2 w-full text-left lg:text-center md:w-auto md:mt-0'>
                <Link
                  target='_blank'
                  rel='noreferrer'
                  to={paths.INTELECTUAL_PROPERTY}
                >
                  {t('footer.intelectual_protection')}
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className='text-[14px] pt-[32px] lg:pt-4 raleway-500'>
          © 2024 Numeraire Future Trends. All rights reserved.
        </div>
        <div className='pt-4 text-[14px] lg:text-left tracking-wide text-[#808080] raleway-400'>
          Any Trademarks are owned by their respective brand owners. No
          trademark owner endorses or sponsors the services of NFTrends or has
          any association and/or affiliation with NFTrends, unless otherwise
          indicated. Contact{' '}
          <span>
            <button className='underline' onClick={handleMailtoClick}>
              HelloNFTrends@nftrends.ai{' '}
            </button>
          </span>
        </div>
      </div>
    </div>
  );
}

export default LandingMain;

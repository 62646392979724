interface IconTypeProps {
    width: string,
    height: string,
    color: string, 
    transform?: string,
    className?: string,
}

export const FileFilterIconCustom = ({ width, height, color }: IconTypeProps) => (
  <svg style={{ width, height, display: 'inline' }} width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="19.0902" height="19.0902" fill={color}/>
    <rect y="23.8623" width="19.0902" height="19.0902" fill={color}/>
    <rect x="23.8628" width="19.0902" height="19.0902" fill={color}/>
    <rect x="23.8628" y="23.8623" width="19.0902" height="19.0902" fill={color}/>
  </svg>
);

export const PlateFilterIconCustom = ({ width, height, color }: IconTypeProps) => (
    <svg style={{ width, height, display: 'inline'}}  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect x="3" y="5" width="18" height="2" fill={color}/>
    <rect x="3" y="11" width="18" height="2" fill={color}/>
    <rect x="3" y="17" width="18" height="2" fill={color}/>
  </svg>
  );

  export const SearchIconCustom = ({ width, height, color }: IconTypeProps) => (
    <svg style={{ width, height, display: 'inline' }}  width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.9 17.3L10.3 11.7C9.8 12.1 9.225 12.4167 8.575 12.65C7.925 12.8833 7.23333 13 6.5 13C4.68333 13 3.146 12.371 1.888 11.113C0.629333 9.85433 0 8.31667 0 6.5C0 4.68333 0.629333 3.14567 1.888 1.887C3.146 0.629 4.68333 0 6.5 0C8.31667 0 9.85433 0.629 11.113 1.887C12.371 3.14567 13 4.68333 13 6.5C13 7.23333 12.8833 7.925 12.65 8.575C12.4167 9.225 12.1 9.8 11.7 10.3L17.325 15.925C17.5083 16.1083 17.6 16.3333 17.6 16.6C17.6 16.8667 17.5 17.1 17.3 17.3C17.1167 17.4833 16.8833 17.575 16.6 17.575C16.3167 17.575 16.0833 17.4833 15.9 17.3ZM6.5 11C7.75 11 8.81267 10.5627 9.688 9.688C10.5627 8.81267 11 7.75 11 6.5C11 5.25 10.5627 4.18733 9.688 3.312C8.81267 2.43733 7.75 2 6.5 2C5.25 2 4.18733 2.43733 3.312 3.312C2.43733 4.18733 2 5.25 2 6.5C2 7.75 2.43733 8.81267 3.312 9.688C4.18733 10.5627 5.25 11 6.5 11Z" fill={color}/>
    </svg>
  );

  export const CloseIconCustom = ({ width, height, color, transform, className }: IconTypeProps) => (
    <svg className={`${className ?? ''}`} style={{ width, height, display: 'inline', transform }}  width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.00005 8.40005L2.10005 13.3C1.91672 13.4834 1.68338 13.575 1.40005 13.575C1.11672 13.575 0.883382 13.4834 0.700048 13.3C0.516715 13.1167 0.425049 12.8834 0.425049 12.6C0.425049 12.3167 0.516715 12.0834 0.700048 11.9L5.60005 7.00005L0.700048 2.10005C0.516715 1.91672 0.425049 1.68338 0.425049 1.40005C0.425049 1.11672 0.516715 0.883382 0.700048 0.700048C0.883382 0.516715 1.11672 0.425049 1.40005 0.425049C1.68338 0.425049 1.91672 0.516715 2.10005 0.700048L7.00005 5.60005L11.9 0.700048C12.0834 0.516715 12.3167 0.425049 12.6 0.425049C12.8834 0.425049 13.1167 0.516715 13.3 0.700048C13.4834 0.883382 13.575 1.11672 13.575 1.40005C13.575 1.68338 13.4834 1.91672 13.3 2.10005L8.40005 7.00005L13.3 11.9C13.4834 12.0834 13.575 12.3167 13.575 12.6C13.575 12.8834 13.4834 13.1167 13.3 13.3C13.1167 13.4834 12.8834 13.575 12.6 13.575C12.3167 13.575 12.0834 13.4834 11.9 13.3L7.00005 8.40005Z" fill={color}/>
    </svg>
  );

  export const EditIconCustom = ({ width, height, color }: IconTypeProps) => (
    <svg style={{ width, height, display: 'inline' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.7279 9.36396L14.3137 7.94974L5 17.2635V18.6777H6.41421L15.7279 9.36396ZM17.1421 7.94974L18.5563 6.53553L17.1421 5.12132L15.7279 6.53553L17.1421 7.94974ZM7.24264 20.6777H3V16.4351L16.435 3H17.8492L20.6777 5.82842V7.24264L7.24264 20.6777Z" fill={color}/>
    </svg>
  );

  export const ArrowRightCustom = ({ width, height, color }: IconTypeProps) => (
    <svg style={{ width, height, display: 'inline' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.60808e-07 12L22 12M22 12L15.5 5.5M22 12L15.5 18.5" stroke={color} strokeWidth="2"/>
    </svg>
  );

  export const ArrowLeftCustom = ({ width, height, color }: IconTypeProps) => (
    <svg style={{ width, height, display: 'inline' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
     <g clipPath="url(#clip0_4550_25000)">
      <path d="M23 12L1 12M1 12L7.5 5.5M1 12L7.5 18.5" stroke={color} strokeWidth="2"/>
      </g>
      <defs>
      <clipPath id="clip0_4550_25000">
      <rect width="24" height="24" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );


  export const DoneIconCustom = ({ width, height, color }: IconTypeProps) => (
    <svg style={{ width, height, display: 'inline' }}  width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.2731 45.7501L9.78564 31.2626L13.4075 27.6407L24.2731 38.5063L47.5929 15.1865L51.2148 18.8084L24.2731 45.7501Z" fill={color}/>
    </svg>
  );


  export const SliderIconCustom = ({ width, height, color, className }: IconTypeProps) => (
    <svg className={`${className ?? ''}`} style={{ width, height, display: 'inline' }}  xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
      <circle cx="36" cy="36" r="34.5" stroke={color} strokeWidth="3"/>
      <path d="M19.5 36.001L52.5 36.001M52.5 36.001L42.75 26.251M52.5 36.001L42.75 45.751" stroke={color} strokeWidth="3"/>
    </svg>
  );

  export const MinusIconCustom = ({ width, height, color, className }: IconTypeProps) => (
    <svg className={`${className ?? ''}`} style={{ width, height, display: 'inline' }}  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Plus">
      <path id="Vector 8" d="M3.00052 12.0005L11.9998 12L20.9995 12.0005" stroke={color} strokeWidth="2"/>
      </g>
    </svg>
  );

  export const PlusIconCustom = ({ width, height, color, className }: IconTypeProps) => (
    <svg className={`${className ?? ''}`} style={{ width, height, display: 'inline' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Plus">
      <path id="Vector 8" d="M12 3L12 12.0001M12 12.0001L3.00068 12.0006M12 12.0001L20.9996 12.0006M12 12.0001L12 20.9994" stroke={color} strokeWidth="2"/>
      </g>
    </svg>
  );






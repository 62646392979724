import LoaderBase from '../Spinner/LoaderBase';
import Spinner from '../Spinner/Spinner';

const LoaderScreen = () => {
     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
     // @ts-expect-error
    const isMobileApp = window?.ReactNativeWebView; 
  return (
    <div className={`absolute top-0 right-0 z-10 ${isMobileApp ? 'bg-blue-normal' : 'bg-white'} w-full h-full flex justify-center items-center`}>
      <div className="animate-pulse">
        <LoaderBase/>
      </div>
    </div>
  );
};

export default LoaderScreen;

import { INVENTORY, PROFILE } from '../endpoints';
import { api } from '..';

const InventoryService = {
    async getAllInventory(page: number, searchValue: string = '', isSort: boolean, typeSorted: string = '', size: string = '100') {
        const params = {page, size}; 
        const data: any = {
            search_string: searchValue
        }
        if(isSort) {
            data.sort = 'name'; 
            data.direction = 'asc'; 
        }
        if(typeSorted) {
            data.type = typeSorted === 'ALL' ? '' : typeSorted; 
        }
        const response: ResponseDataPaginationI = await api.post(INVENTORY.GET_ALL_INVENTORY, data, {params}); 
        return response; 
    },
    async createInventory(data: any) {
        const response: {id:string} = await api.post(INVENTORY.INVENTORY_BASE, {...data}); 
        return response;
    },
    async assignInventory(data: any) {
        const response = await api.post(INVENTORY.ASSIGN, {...data}); 
        return response;
    },
    async deleteInventory(id: string) {
        return await api.delete(`${INVENTORY.INVENTORY_BASE}/${id}`); 
    },
    async editInventory(data: any) {
        return await api.put(INVENTORY.INVENTORY_BASE, {...data}); 
    },
    async getUsers(page: number, searchValue?: any) {
        const params = {page};
        const filterOptions = (searchValue && Object.keys(searchValue).length) ? [...searchValue] : []; 
        const data = {
            'search_criteria_list': filterOptions
        }
        const response:ResponseDataPaginationI = await api.post(INVENTORY.GET_USERS, data,  {params}); 
        return response; 
    },
    async getUserProfile(id:string) {
        const response: User = await api.get(`${INVENTORY.GET_USER}/${id}`);
        return response; 
    },
    async getUserAssign(id:string) {
        const response: Inventory[] = await api.get(`${INVENTORY.GET_ASSIGN_USER}/${id}`);
        return response; 
    },
    async getInventoryById(id:string) {
        const response: Inventory = await api.get(`${INVENTORY.INVENTORY_BASE}/${id}`);
        return response; 
    },
    async getAssignInventoryUsers(ids?: string[] | null, name?: string) {
        const data:any = {}; 
        if(ids) {
            data.ids = [...ids]; 
        }
        if(name) {
            data.name = name; 
        }
        const response = await api.post(INVENTORY.USERS_ASSIGN, {...data});
        return response; 
    },
    async editUserProfile(id:string, data:any) {
        return await api.put(`${INVENTORY.EDIT_USER}/${id}`, {...data}); 
    },
    async uploadLicenses(id:string, data:any) {
        return await api.put(`${INVENTORY.INVENTORY_BASE}/${id}/licenses`, data, { headers: {
            'Content-Type': 'multipart/form-data',
          }}); 
    },
    async createUser(data: any) {
        const response: User = await api.post(INVENTORY.CREATE_USER, {...data}); 
        return response;
    },
    async deleteUser(id: string) {
        return await api.delete(PROFILE.DELETE_ACCOUNT, {id}); 
    },
};

export default InventoryService; 
import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import Admin from '../pages/Admin';
import { paths } from './constants';
import ProtectedRoute from './protectedRoute';
import ProtectedAdminRoute from './protectedAdminRoute';
import SubscriptionWrapper from '../pages/Subscription/SubscriptionWrapper';
import ProtectedSalesRoute from './protectedSalesRoute';
import ProtectedInventoryRoute from './protectedInventoryRoute';
import ProtectedDigitalArtistRoute from './protectedDigitalArtistRoute';
import {Helmet} from 'react-helmet'; 
import LandingMain from '../pages/Home/LandingMain';

// eslint-disable-next-line react/display-name
const Loadable = (Component: any) => (props:any) => {
  return   <Suspense fallback={<div></div>}>
            <Helmet>
                <title>{props.title ?? ''}</title>
                <meta name="description" content={props.title} />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
           <Component {...props} />
    </Suspense>
}; 

const Profile = Loadable(lazy(() => import('../pages/Profile/index')));
const StartPage = Loadable(lazy(() => import('../pages/Home/StartPage')));
const ItemsMain= Loadable(lazy(() => import('../pages/Items/index')));
const Items= Loadable(lazy(() => import('../pages/Items/Items')));
const Inventory= Loadable(lazy(() => import('../pages/Inventory/index')));
const ItemDetailByCode= Loadable(lazy(() => import('../pages/ItemDetailByQRCode/index')));
const DPPExamplePage = Loadable(lazy(() => import('../pages/ItemDetailByQRCode/DPPExample')));
const DPPExampleQRcode = Loadable(lazy(() => import('../pages/ItemDetailByQRCode/DPPExampleQRcode')));
const InventoryDetails= Loadable(lazy(() => import('../pages/Inventory/InventoryDetails')));
const ItemDetail= Loadable(lazy(() => import('../pages/Items/ItemDetails')));
const ItemDetailWrapper= Loadable(lazy(() => import('../pages/Items/ItemDetailsWrapper')));
const ItemSell= Loadable(lazy(() => import('../pages/Items/ItemSell')));
const CreateNewAccountForClient= Loadable(lazy(() => import('../pages/Items/CreateNewAccountForClient')));
const LastCheck= Loadable(lazy(() => import('../pages/Items/LastCheck')));
const ItemSellBuyer= Loadable(lazy(() => import('../pages/ItemsInstitutialBuyer/ItemSellBuyer')));
const LoanItem = Loadable(lazy(() => import('../pages/Items/LoanItem')));
const AuthenticateItem = Loadable(lazy(() => import('../pages/ItemsInstitutialBuyer/AuthenticateItem')));
const ItemsSuccessfullySold= Loadable(lazy(() => import('../pages/Items/ItemsSuccessfullySold')));
const SuccessPrepareItem= Loadable(lazy(() => import('../pages/Items/SuccessPrepareItem')));
const ValidationSalesParticipants= Loadable(lazy(() => import('../pages/ItemsInstitutialBuyer/ValidationSalesParticipants')));
const FinalValidationParticipants= Loadable(lazy(() => import('../pages/ItemsInstitutialBuyer/FinalValidationParticipants')));
const VerifiedItem= Loadable(lazy(() => import('../pages/ItemsInstitutialBuyer/ItemSellCheck')));
const ItemSellFinish= Loadable(lazy(() => import('../pages/ItemsInstitutialBuyer/ItemSellFiinish')));
const People= Loadable(lazy(() => import('../pages/People/index')));
const PeopleProfile= Loadable(lazy(() => import('../pages/People/PeopleProfile')));
const AddUserSuccess= Loadable(lazy(() => import('../pages/People/AddUserSuccess')));
const AdminProfile= Loadable(lazy(() => import('../pages/AdminProfile/index')));
const Home= Loadable(lazy(() => import('../pages/Home/index')));
const Subscription= Loadable(lazy(() => import('../pages/Subscription/index')));
const AddItemInfo = Loadable(lazy(() => import('../pages/Items/AddItemInfo')));
const CreateNFDI = Loadable(lazy(() => import('../pages/Items/CreateNFDI')));
const PageNotFound =  Loadable(lazy(() => import('../pages/Page404/index')));
const UserNotFoundPage =  Loadable(lazy(() => import('../pages/Page404/UserNotFound')));
const UnknownErrorPage =  Loadable(lazy(() => import('../pages/Page404/UnknownErrorPage')));
const ComingSoon =  Loadable(lazy(() => import('../pages/Page404/ComingSoon')));
const More = Loadable(lazy(() => import('../pages/More/index')));
const Payment = Loadable(lazy(() => import('../pages/Subscription/PaymentComponent')));
const Onboarding = Loadable(lazy(() => import('../pages/Home/Onboarding')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/More/PrivacyPolicy')));
const TermsOfUse = Loadable(lazy(() => import('../pages/More/TermsOfUse')));
const IntelectualProperty = Loadable(lazy(() => import('../pages/More/IntelectualProperty')));
const SuccessApprove = Loadable(lazy(() => import('../pages/More/SuccessApprove')));
const Archive = Loadable(lazy(() => import('../pages/More/Archive')));
const ValidationDRCode = Loadable(lazy(() => import('../pages/DisplayRemotellyExternal/ValidationDRCode')));
const ItemDetailExternal = Loadable(lazy(() => import('../pages/DisplayRemotellyExternal/ItemDetailExternal')));

const routes: any = [
/*   {
    path: paths.ROOT,
    element: <LandingMain />
  }, */
  {
    path: paths.ROOT,
    element: <StartPage />
  },
  {
    path: paths.DASHBOARD,
    element: <ProtectedRoute>
        <Dashboard/>
      </ProtectedRoute>,
    children: [
      {
        path: '',
        element: <Navigate to="/dashboard/items" />
      },
      {
        path: paths.PROFILE,
        element: <Profile title='Profile'/>
      },   
      {
        path: paths.ITEMS,
        element:  <ItemsMain/>,
        children: [
          {
            path: '',
            element: <Items title='Items'/>
          },
          {
            path: paths.ADD_ITEM,
            element: <ProtectedInventoryRoute>
              <AddItemInfo title='Create item'/>
            </ProtectedInventoryRoute> 
          },
          {
            path: paths.ADD_NFDI,
            element: <ProtectedDigitalArtistRoute>
              <CreateNFDI title='Create NFDI'/>
            </ProtectedDigitalArtistRoute> 
          },
          {
            path: paths.ITEM_DETAIL,
            element: <ItemDetailWrapper title='Item detail'/>
          },
          {
            path: paths.ITEM_SELL,
            element: <ProtectedSalesRoute>
              <ItemSell title='Item sell'/>
            </ProtectedSalesRoute> 
          },
          {
            path: paths.ITEM_CHECK_ACCOUNT,
            element: <ProtectedSalesRoute>
              <CreateNewAccountForClient title='Create new account'/>
            </ProtectedSalesRoute> 
          },
          {
            path: paths.ITEM_LAST_CHECK,
            element: <ProtectedSalesRoute>
              <LastCheck title='Last check'/>
            </ProtectedSalesRoute>
          },
          {
            path: paths.ITEM_SUCCESSFULLY_SOLD,
            element: <ProtectedSalesRoute>
              <ItemsSuccessfullySold title='Item sold'/>
            </ProtectedSalesRoute> 
          },
          {
            path: paths.LOAN_ITEM,
            element: <LoanItem title='Loan item'/>
          },
          {
            path: paths.ITEM_SELL_PREPARE,
            element: <ItemSellBuyer title='Item sell'/>
          },
          {
            path: paths.ITEM_SUCCESS_PREPARE,
            element: <SuccessPrepareItem title='Prepare'/>
          },
          {
            path: paths.VALIDATION_PARTICIPANTS,
            element: <ValidationSalesParticipants title='Validation'/>
          },
          {
            path: paths.FINAL_VALIDATION,
            element: <FinalValidationParticipants title='Final validation'/>
          },
          {
            path: paths.VERIFIED_ITEM,
            element: <VerifiedItem title='Verified'/>
          },
          {
            path: paths.ITEM_SELL_BUYER,
            element: <ItemSellFinish title='Sell finish'/>
          },
          {
            path: paths.AUTHENTICATE,
            element: <AuthenticateItem title='Auth item'/>
          }
        ]
      },
      {
        path: paths.SUBSCRIPTION,
        element: <SubscriptionWrapper/>,
        children: [
          {
            path: '',
            element: <Subscription title='Subscription'/>,
          },
          {
            path: paths.PAYMENT,
            element: <Payment title='Payment'/>
          }
        ]
      },
      {
        path: paths.MORE,
        element: <More title='More'/>
      },
    ]
  },
  {
    path: paths.ADMIN,
    element: <ProtectedAdminRoute>
        <Admin/>
      </ProtectedAdminRoute>,
    children: [
      {
        path: '',
        element: <Navigate to="/admin/people" />
      },
      {
        path: paths.PROFILE,
        element: <AdminProfile title='Admin profile'/>
      },  
      {
        path: paths.PEOPLE,
        element: <People title='People'/>
      },
      {
        path: paths.ADD_PEOPLE,
        element: <PeopleProfile title='People details'/>
      },
      {
        path: paths.ADD_SUCCESS,
        element: <AddUserSuccess title='Add user'/>
      },
      {
        path: paths.EDIT_PEOPLE,
        element: <PeopleProfile title='User profile'/>
      },
      {
        path: paths.INVENTORY,
        element: <Inventory title='Company'/>
      },
      {
        path: paths.ADD_INVENTORY,
        element: <InventoryDetails title='Company detail'/>
      },
      {
        path: paths.EDIT_INVENTORY,
        element: <InventoryDetails title='Company edit'/>
      },
      {
        path: paths.MORE,
        element: <More title='Admin more'/>
      },
    ]
  },
  {
    path: paths.ITEM_DETAIL_BY_CODE,
    element: <ItemDetailByCode title='Item by code'/>,
  },
  {
    path: paths.DPP_EXAMPLE,
    element: <DPPExamplePage title='Dpp example'/>,
  },
  {
    path: paths.ONBOARDING,
    element: <Onboarding title='Obnboarding'/>,
  },
  {
    path: paths.DPP_EXAMPLE_QR_CODE,
    element: <DPPExampleQRcode title='Dpp example code'/>,
  },
  {
    path: paths.USER_NOT_FOUND,
    element: <UserNotFoundPage title='User not found'/>
  },
  {
    path: paths.UNKNOWN_ERROR,
    element: <UnknownErrorPage title='Unknown error'/>
  },
  {
    path: paths.COMMING_SOON,
    element: <ComingSoon title='Coming soon'/>
  },
  {
    path: paths.POLICY,
    element: <PrivacyPolicy title='Privacy policy'/>
  },
  {
    path: paths.TERMS_OF_USE,
    element: <TermsOfUse title='Terms of use'/>
  },
  {
    path: paths.INTELECTUAL_PROPERTY,
    element: <IntelectualProperty title='Intelectual property'/>
  },
  {
    path: paths.SUCCESS_APPROVE,
    element: <SuccessApprove title='Success approve'/>
  },
  {
    path: paths.ARCHIVE,
    element: <Archive title='Archive'/>
  },
  {
    path: paths.VALIDATION_DISPLAY_REMOTELLY,
    element: <ValidationDRCode title='Validation code'/>
  },
  {
    path: paths.ITEM_EXTERNAL,
    element: <ItemDetailExternal title='Item detail external'/>
  },
  {
    path: '*',
    element: <PageNotFound title='Page not found'/>
  }
];

export default routes;

import jwtDecode from 'jwt-decode';
import { CUSTOM_CLAIMS_DEVICE_KEY, LonStatusArtistEnum, LonStatusEnum, Roles } from '../constants';
import { type Role } from '../types';

export function compareNames(a:any, b:any) {
    const nameA = a.name?.toUpperCase();
    const nameB = b.name?.toUpperCase();
  
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
  }

  export const geocodeByAddressApi = (address:any) => {
    const geocoder = new window.google.maps.Geocoder();
    const geocodeOptions = {
      address,
      region: 'en',
      language: 'en',
    };

    return new Promise((resolve, reject) => {
      geocoder.geocode(geocodeOptions, (results, status) => {
        if (status === 'OK') {
          resolve(results);
        } else {
          reject(status);
        }
      });
    });
  }; 
  
  export const getHumanizeFormatDateOne = (inputDateStr: string) => {
    const inputDate = new Date(inputDateStr);
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const outputDateStr = `${monthNames[inputDate.getMonth()]} ${inputDate.getDate()}, ${inputDate.getFullYear()}`;
    return outputDateStr; 
  }

  export const getHumanizeFormatDateHHMM = (inputDateStr: string) => {
      const inputDate = new Date(inputDateStr);

      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      const addLeadingZero = (number: any) => (number < 10 ? '0' : '') + number;

      // Format the output date string
      const outputDateStr = `${addLeadingZero(inputDate.getDate())}.${addLeadingZero(inputDate.getMonth() + 1)}.${inputDate.getFullYear()} / ${addLeadingZero(inputDate.getHours())}:${addLeadingZero(inputDate.getMinutes())}`;
      return outputDateStr; 
  }
 
 export const humanizeCurrency = (number: number) => {
    const transformedNumber = (number / 100).toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return transformedNumber; 
  }

export const isGracePeriodCheck = (date: string) => {
    const inputDate: Date = new Date(date);
    const currentDate: Date = new Date();
    const timeDifference: number = inputDate.getTime() - currentDate.getTime();
    const daysDifference: number = timeDifference / (1000 * 60 * 60 * 24);
    if (daysDifference < 10) {
      return false; 
    } else {
      return true; 
    }
} 

export  function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function isMP4(urlString: string) {
  const url = decodeURIComponent(urlString); 
  let filename = url.split('?')[0];
  if(filename) {
    filename = filename.split('?')[0];
  }
  const isMP4File = /\.mp4$/.test(`${filename}`);
  return isMP4File;
}

export const setImagesHandler = (imagesList: any) => {
  return imagesList.filter((item: any) => !isMP4(item.path_url))[0]?.path_url;
}

export const formatTime = (date: string) => {
  const formattedDate = new Date(date).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).replace(/\//g, '.');

  const formattedTime = new Date(date).toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
  });
  return `${formattedDate} / ${formattedTime}`; 
}

export function convertUTCToLocal(utcDateString: string) {
  const date = new Date(utcDateString);
  const utcTimestamp = date.getTime();
  const localDate = new Date(utcTimestamp + (3 * 60 * 60 * 1000));

  const formattedDate = localDate.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).replace(/\//g, '.');

  const formattedTime = localDate.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
  });

  const offsetTime = showTimeZoneOffset(utcDateString); 
  return `${formattedDate} / ${formattedTime} (${offsetTime})`; 
}


export function formatTimeDDMM(dateString: string) {
  const date = new Date(dateString);
  const options: any = { day: 'numeric', month: 'long' };
  return date.toLocaleString('en-US', options);
}

export const formatTimeRemaining = (time: any) => {
  const minutes = Math.floor(time / 60);
  return `${minutes} min`;
};

export const showTimeZoneOffset = (date: string) => {
  const inputDate = new Date(date);
  const utcOffsetMinutes = inputDate.getTimezoneOffset();
  const utcOffsetHours = Math.abs(utcOffsetMinutes / 60);
  const formattedUtcOffset = utcOffsetMinutes < 0 ? `UTC+${utcOffsetHours}` : `UTC-${utcOffsetHours}`;
  return formattedUtcOffset; 
}

export const hoursPassedSinceDate = (targetDate: string): number => {
  const inputDate: Date = new Date(targetDate);
  const currentDate: Date = new Date();
  const timeDifference: number = currentDate.getTime() - inputDate.getTime();
  const hoursPassed: number = timeDifference / (1000 * 60 * 60);
  return Math.round(hoursPassed);
}

export const checkBuyerRole = (role: Role): boolean => {
  return role === Roles.MAIN_REPRESENTATIVE || role === Roles.CURATOR_REPRESENTATIVE || role === Roles.IT_TECHNICAL_REPRESENTATIVE || role === Roles.BUYER || role === Roles.DIGITAL_ARTIST; 
}

export const handleMailtoClick = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
 // @ts-expect-error
 if (window?.ReactNativeWebView) {
   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
   // @ts-expect-error
   window?.ReactNativeWebView?.postMessage('mailto');
 } else {
   window.location.href = 'mailto:HelloNFTrends@nftrends.ai'; 
 }
}

export const setProgressLoanStatus = (status: LonStatus, userRole: Role | undefined) => {
  if(userRole !== Roles.DIGITAL_ARTIST) {
    return LonStatusEnum[status] ?? ''; 
  } else {
    return LonStatusArtistEnum[status] ?? ''; 
  }
}

export const formatTimeQrCodeProgress = (value: any) => {
  return value.toString().padStart(2, '0');
};

export const getTransactionsSorted = (transactionsList: Transactions[]): Transactions[] => {
  const transactionsSorted = transactionsList.sort(
    (a: any, b: any) =>
      new Date(b.date).valueOf() - new Date(a.date).valueOf()
  );
  return transactionsSorted; 
};

export const checkIsDeviceAuthToken = (token: string): boolean => {
  const decodedToken:any = jwtDecode(token); 
  console.log('decodedToken', decodedToken); 
  return !!decodedToken[CUSTOM_CLAIMS_DEVICE_KEY]; 
}

export const showFormatTime = (date: string) => {
  const currentTime = formatTime(date); 
  const offsetTime = showTimeZoneOffset(date); 
  return `${currentTime} (${offsetTime})`; 
}

export function getTimeHHmmFormat(dateString: string): string {
  const date: Date = new Date(dateString);
  const hours: number = date.getHours();
  const minutes: number = date.getMinutes();
  const formattedHours: string = hours < 10 ? '0' + String(hours) : String(hours);
  const formattedMinutes: string = minutes < 10 ? '0' + String(minutes) : String(minutes);
  return `${formattedHours}:${formattedMinutes}`;
}

export const getHoursToEndGracePeriod = (date: string): string => {
    const lastEvent: string = date;
    const lastEventTimestamp: number = Date.parse(lastEvent); 
    const newTimestamp: number = lastEventTimestamp + 48 * 60 * 60 * 1000; 
    const currentTimestamp: number = Date.now();
    const timeDifference: number = newTimestamp - currentTimestamp;
    const hours: number = Math.floor(timeDifference / (60 * 60 * 1000));
    const minutes: number = Math.floor((timeDifference % (60 * 60 * 1000)) / (60 * 1000));
    const formattedTime: string = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
 
    return formattedTime; 
}

export const isMoreThan48Hours = (date: string): boolean => {
    const endDate: string = date;
    const endDateTimestamp: number = Date.parse(endDate);
    const currentTimestamp: number = Date.now();
    const timeDifference: number = endDateTimestamp - currentTimestamp;
    const isMoreThan48Hours: boolean = timeDifference > (48 * 60 * 60 * 1000);
    return isMoreThan48Hours; 
}

export function isWithin24Hours(dateString: string): boolean {
  const date = new Date(dateString);
  const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);
  return date >= twentyFourHoursAgo;
}

export function isValidRangeHandler(a: number, b: number): boolean {
  const difference = Math.abs(a - b);
  return difference >= 0 && difference <= 150; 
}

export const getAllowedCompanyType = (role: any): '' | 'INSTITUTIONAL' | 'GENERAL' | 'ALL' => {
  if(role === Roles.MANAGER) {
    return 'ALL'; 
  } else if(role === Roles.INVENTORY_SALES || role === Roles.DEALER || role === Roles.EXPERT)  {
    return 'GENERAL'
  } else {
    return 'INSTITUTIONAL'; 
  }
}

export const filterImages = (imagesList: ImageModel[]): any => {
  if (imagesList.length > 1) {
    const video = imagesList.find(item => item.type === 'ITEMS_VIDEO_THUMBNAIL'); 
    if(video) {
      return [video]; 
    } else {
      const images = imagesList.filter(item => item.type === 'THUMBNAIL_LARGE'); 
      return images; 
    }
  } else {
    return imagesList;
  }
};

export function addWeeksToDate(week: number, startDate?: any): string {
  const currentDate = startDate ? new Date(startDate) : new Date();
  const daysToAdd = week * 7; // Convert weeks to days
  const newDate = new Date(currentDate.getTime() + daysToAdd * 24 * 60 * 60 * 1000); // Add the number of milliseconds corresponding to the number of days

  // Format the date as "YYYY-MM-DD"
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, '0');
  const day = String(newDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function weeksUntilExpiration(createdDate: string, expiredDate: string) {
  const created = new Date(createdDate);
  const expired = new Date(expiredDate);
  const timeDiff = expired.getTime() - created.getTime();
  const weeksDiff = timeDiff / (1000 * 60 * 60 * 24 * 7);
  const result = weeksDiff < 1 ? 1 : Math.ceil(weeksDiff); 
  return result;
}

export function checkExpireDate(dateString: string): boolean {
  const inputDate = new Date(dateString);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return inputDate <= today;
}

export function getDaysSinceExpired(dateString: string): number {
  const inputDate = new Date(dateString);
  const today = new Date();
  const timeDiff = today.getTime() - inputDate.getTime();
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  // If the input date is in the future, return 0
  return inputDate > today ? 0 : daysDiff;
}

export function generateSerialnumber(serialNumber: string, index: number): string {
  const timestamp = Date.now();
  const randomNumber = Math.random() * 10000 | 0;
  return `${serialNumber}-${timestamp}-${randomNumber}-${index}`;
}

export function generateFileId(fileName: string, index: number): string {
  const formattedName = fileName.replace(/\./, '-').replace(/\.[^/.]+$/, '');
  const randomNumber = Math.random() * 10000 | 0;
  return `${formattedName}-${randomNumber}-${index}`;
}

export const formatNumberWith2Digits = (value: number) => {
  return value.toString().padStart(2, '0');
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import { Roles } from './constants';

interface Props {
    children: JSX.Element, 
}

const ProtectedInventoryRoute = ({children}: Props) => {
    const { userData } = useAuthContext(); 

    if(userData?.userRole === Roles.UBER_ADMIN) {
        return <Navigate to='/admin/people' replace/>
    } else if(userData?.userRole !== Roles.MANAGER && userData?.userRole !== Roles.INVENTORY_SALES) {
        return <Navigate to='/dashboard/items' replace/>
    }
    
    return children; 

};

export default ProtectedInventoryRoute; 
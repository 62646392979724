import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useListenInternetConnection } from '../hooks/useListenInternetConnection';
import ProfileService from '../api/services/profileService';
import { handleError } from '../common/utils/handleError';
import { useAuthContext } from './AuthContext';
import { Roles } from '../common/constants';
import ItemsService from '../api/services/itemsService';
import InventoryService from '../api/services/inventoryService';

interface Props {
    children: JSX.Element, 
}

type CheckedFieldsI = Record<
  string,
  {
    active: 'right' | 'left';
  }
>;

const AppContext = createContext<AppContextInterface>({
    isOnline: true,
    itemsToSell: [], 
    addItemToSell: (item: ItemToSellI | null) => null, 
    deleteItemToSell: (id: string) => null, 
    profileDetail: null,
    setProfileDetail: (profile: ContactDetailsI) => null,
    subscriptionData: null,
    getSubscriptionData: () => null, 
    setSubscriptionData: (value: SubscriptionDataI | null) => null, 
    abilityToSell: undefined, 
    abilityToBuy:  undefined, 
    cancelSubscription: () => null, 
    isOpenDigigtalPassport: false,
    setIsOpenDigitalPassport: (value: boolean) => null, 
    refreshItemDetails: false,
    setRefreshItemDetails: (value: boolean) => null,
    valueLanding: {}, 
    setValue: (value: CheckedFieldsI) => null,
    prevItemIdx: 10,
    setPrevItemIdx: (item: number) => null, 
    prevModelId: '',
    setPrevModelId: (id: string) => null,
    isNoBuerFlow: true, 
    isInstitutialFlow: false, 
    userCompany: null, 
    isModalOpen: false,
    setIsModalOpen: (value: boolean) => null,
    setSellMessage: (message: {item_id: string, model_id: string}) => null, 
});

export function useAppContext() {
    return useContext(AppContext);
}

const AppProvider = ({ children }: Props) => {
    const {isOnline} = useListenInternetConnection();
    const [itemsToSell, setItemsToSell] = useState<ItemToSellI[]>([]);
    const [subscriptionData, setSubscriptionData] = useState<SubscriptionDataI | null>(null); 
    const [profileDetail, setProfileDetail] = useState<ContactDetailsI | null>(null);
    const {userData} = useAuthContext();
    const [abilityToSell, setAbilityToSell] = useState<boolean>(true); 
    const [abilityToBuy, setAbilityToBuy] = useState<boolean>(true); 
    const [isOpenDigigtalPassport, setIsOpenDigitalPassport] = useState(false); 
    const [refreshItemDetails, setRefreshItemDetails] = useState(false); 
    const [sellMessage, setSellMessage] = useState<{item_id: string, model_id: string} | null>(null);
    const [prevItemIdx, setPrevItemIdx] = useState(10); 
    const [valueLanding, setValue] = useState<CheckedFieldsI>({});
    const [prevModelId, setPrevModelId] = useState(''); 
    const [userCompany, setUserCompany] = useState<Inventory | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const isNoBuerFlow = useMemo(() => userData?.userRole === Roles.UBER_ADMIN || userData?.userRole === Roles.MANAGER || userData?.userRole === Roles.DEALER || userData?.userRole === Roles.INVENTORY_SALES, [userData?.userRole]); 

    const isInstitutialFlow = useMemo(() => userData?.userRole === Roles.CURATOR_REPRESENTATIVE || userData?.userRole === Roles.IT_TECHNICAL_REPRESENTATIVE || userData?.userRole === Roles.MAIN_REPRESENTATIVE || userData?.userRole === Roles.DIGITAL_ARTIST, [userData?.userRole]); 

    const addItemToSell = (currentItem: ItemToSellI | null) => {
        if(currentItem) {
            const idxItem = itemsToSell.findIndex(elem => elem.id === currentItem.id); 
            if(idxItem === -1) {
                setItemsToSell([...itemsToSell, currentItem]);
            } else {
                const newItems = [...itemsToSell]; 
                newItems[idxItem] = currentItem; 
                setItemsToSell(newItems); 
            }
        } else {
            setItemsToSell([]); 
        }
    }

    const deleteItemToSell = (id: string) => {
        const newList = itemsToSell.filter(item => item.id !== id); 
        setItemsToSell(newList); 
    }

    const getSubscriptionData = async (isShowErrorNotify: boolean = false) => {
        try {
            const result = await ProfileService.getSubscription(); 
            if(result) {
                setSubscriptionData(result); 
            }
        } catch (error: any) {
            if(isShowErrorNotify) {
                handleError(error.message);
            }
        }
    }

    const cancelSubscription = async () => {
        try {
            if(subscriptionData?.subscription_id) {
                const result = await ProfileService.cancelSubscription(subscriptionData?.subscription_id); 
                console.log('result', result); 
                getSubscriptionData(); 
            }
        } catch (error: any) {
            handleError(error.message); 
        }
    }

    const getAbilityToSell = async () => {
        try {
            const result = await ItemsService.getAbilityToSell(1); 
            if(result) {
                setAbilityToSell(result.ability); 
            } 
        } catch (error) {
            console.log('error', error); 
        }

    }

    const getAbilityToBuy = async () => {
        try {
            const count = subscriptionData?.plan_name === 'Basic' ? 5 : 20; 
            const result  = await ItemsService.getAbilityToBuy(count); 
            if(result){
                setAbilityToBuy(result.ability); 
            }  
        } catch (error) {
            console.log('error', error); 
        }
    }

    const getUserCompany = async (userId: string) => {
        try {
            const company = await InventoryService.getUserAssign(userId);
            if(company){
                setUserCompany(company[0]);  
            }  
        } catch (error) {
            console.log('error', error); 
        }
    }

    const handleClearSellList = (modelId: string, itemId: string) => {
        const newItemToSell = [...itemsToSell]; 
        const idxItem = newItemToSell.findIndex(item => item.id === modelId); 
        if(idxItem !== -1) {
          if(!newItemToSell[idxItem].listItems[0].values.length) {
            deleteItemToSell(newItemToSell[idxItem].id); 
            return;
          }
          const idxValue = newItemToSell[idxItem]?.listItems[0]?.values.findIndex(el => el.id === itemId); 
          if(idxValue !== -1) {
               newItemToSell[idxItem].listItems[0].values.splice(idxValue, 1); 
               if(newItemToSell[idxItem].listItems[0].values.length === 0) {
                 deleteItemToSell(newItemToSell[idxItem].id); 
              } else {
                addItemToSell(newItemToSell[idxItem]); 
              } 
          } 
        } 
      }

    useEffect(() => {
        if(sellMessage) {
            handleClearSellList(sellMessage.model_id, sellMessage.item_id); 
        }
    }, [sellMessage?.item_id])
    

    useEffect(() => {
        if(userData?.userRole) {
            getSubscriptionData(false); 
        }
        if(userData?.account_id) {
            getUserCompany(userData?.account_id); 
        }
    }, [userData?.userRole]); 

    useEffect(() => {
        if(subscriptionData?.plan_name) {
            getAbilityToSell();
        }
       // getAbilityToBuy(); 
    }, [subscriptionData?.plan_name]); 

    const value = {
        isOnline,
        itemsToSell,
        addItemToSell, 
        deleteItemToSell,
        profileDetail,
        setProfileDetail,
        subscriptionData,
        getSubscriptionData,
        setSubscriptionData,
        abilityToBuy,
        abilityToSell,
        cancelSubscription,
        isOpenDigigtalPassport,
        setIsOpenDigitalPassport,
        refreshItemDetails,
        setRefreshItemDetails,
        valueLanding,
        setValue,
        prevItemIdx,
        setPrevItemIdx,
        prevModelId,
        setPrevModelId,
        isNoBuerFlow, 
        isInstitutialFlow,
        userCompany,
        isModalOpen, 
        setIsModalOpen,
        setSellMessage
    };
    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
} 

export default AppProvider; 
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import { Roles } from './constants';

interface Props {
    children: JSX.Element, 
}

const ProtectedRoute = ({children}: Props) => {
    const { isAuth, userData, isDeviceAuthToken } = useAuthContext(); 

    if(isDeviceAuthToken) {
        return <Navigate to='/item_external' replace/>
    }

    if(!isAuth) {
        return <Navigate to='/' replace/>
    } 

    if(!userData?.userRole) {
        return <Navigate to='/user_moderation' replace/>
    } 

    if(userData?.userRole === Roles.UBER_ADMIN) {
        return <Navigate to='/admin/people' replace/>
    }

    
    return children; 

};

export default ProtectedRoute; 
import './App.css';
import './i18n';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import AppLayout from './layouts/AppLayout';
import { useAuthContext } from './contexts/AuthContext';
import routes from './common/routes';
import LoaderScreen from './components/Loaders/LoaderScreen';
import AppProvider from './contexts/AppContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-1E6SZYP7G4');

function App () {
  const { isInit, isCheckedRNAuth0 } = useAuthContext();
  const content = useRoutes(routes); 

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const isRNApp = window?.ReactNativeWebView; 

  const renderContent = () => {
    if(isInit) {
      if(isRNApp) {
         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        window?.ReactNativeWebView?.postMessage(`send status of isCheckedRNAuth0: ${isCheckedRNAuth0}`);
      }

      if(!isRNApp) {
        return content;
      } else if(isRNApp && isCheckedRNAuth0) {
        return content;
      } else {
        return <LoaderScreen/>; 
      }
    } else {
      return <LoaderScreen/>; 
    }
  }

  return (
    <AppProvider>
      <AppLayout>
          <React.Fragment>
            {renderContent()} 
            <ToastContainer/>
          </React.Fragment>
      </AppLayout>
    </AppProvider>
  )
}

export default App; 

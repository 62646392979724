import React from 'react';
import { ReactComponent as CloseIcon } from '../../assets/images/close_icon.svg';
import { useNavigate } from 'react-router-dom';

interface PropsI {
  handlerClose: () => void; 
  text: string; 
}

function SubscriptionInformer({handlerClose, text}: PropsI) {
  const navigate = useNavigate(); 

  const handlerClick = () => {
    navigate('/dashboard/subscription');
    handlerClose(); 
  }
  return (
    <div className='fixed bottom-0 z-[100] w-full bg-yellow flex justify-between items-center px-4 py-4'>
      <div className='w-[90%] text-[14px] font-medium cursor-pointer' onClick={handlerClick}>{text}</div>
      <div className='w-[10%] flex justify-end'>
         <CloseIcon className='h-3 w-3 cursor-pointer' onClick={handlerClose}/>
      </div>
    </div>
  );
}

export default SubscriptionInformer;

import NavbarAdmin from '../components/Navbar/NavbarAdmin';
import { useAppContext } from '../contexts/AppContext';

interface Props {
    children: JSX.Element, 
}

function AdminLayout({children}:Props) {
  const {isModalOpen} = useAppContext();
  return (
    <div className='flex flex-col h-full'>
        <div className={`hidden lg:flex fixed z-[100] w-full ${!isModalOpen ? '': 'lg:pr-[15px]'}`}>
           <NavbarAdmin/>
        </div>
        <div className='lg:pt-[86px]'>
             {children}
        </div>
    </div>
  )
}

export default AdminLayout; 
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import { Roles } from './constants';

interface Props {
    children: JSX.Element, 
}

const ProtectedAdminRoute = ({children}: Props) => {
    const { isAuth, userData, isDeviceAuthToken } = useAuthContext(); 

    if(isDeviceAuthToken) {
        return <Navigate to='/item_external' replace/>
    }

    if(!isAuth) {
        return <Navigate to='/' replace/>
    } else if(!userData?.userRole) {
        return <Navigate to='/user_moderation' replace/>
    } else if (userData?.userRole !== Roles.UBER_ADMIN) {
        return <Navigate to='/dashboard/items' replace/>
    }

    
    return children; 

};

export default ProtectedAdminRoute; 
import React, { useState, useEffect } from 'react';
import StickyBox from 'react-sticky-box';

export const ResponsiveStickyBox = ({ children, offsetTop = 0, offsetBottom = 0 } : any) => {
  const [isDesktop, setIsDesktop] = useState(false);

/*   useEffect(() => {
    const checkIfDesktop = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    checkIfDesktop();
    window.addEventListener('resize', checkIfDesktop);

    return () => { window.removeEventListener('resize', checkIfDesktop); };
  }, []); */

  if (isDesktop) {
    return (
      <StickyBox offsetTop={offsetTop} offsetBottom={offsetBottom}>
        {children}
      </StickyBox>
    );
  }

  return <>{children}</>;
};
import 'react-responsive-carousel/lib/styles/carousel.min.css'; 
import { Carousel } from 'react-responsive-carousel';

interface PropsI {
    images: string[],
}

function FadeCarousel({images}: PropsI) {
  return (
    <Carousel showThumbs={false} showStatus={false} showArrows={false} infiniteLoop={true} autoPlay={true} interval={3000} stopOnHover={false} transitionTime={1000} swipeable={false} className='w-full h-full' showIndicators={false} animationHandler="fade">
    {images.map((item, idx) => <div key={idx} className='w-full h-[500px] lg:h-screen'>
      <img 
        src={item} 
        className='h-full w-full'
        style={{ objectFit: 'cover' }} 
        alt='images'/>
    </div>)}
  </Carousel>
  )
}

export default FadeCarousel; 
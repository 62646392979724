import { useState } from 'react';
import { ReactComponent as ArrowDown } from '../../assets/images/arrow_down_white.svg';
import { ReactComponent as ArrowDownBlack } from '../../assets/images/arrow_down.svg';
import { useAppContext } from '../../contexts/AppContext';


export default function LanguageSelector() {
  const [language, setLanguage] = useState('en');
  const {isNoBuerFlow} = useAppContext();

  const handleLanguageChange = (e: any) => {
    setLanguage(e.target.value);
  };

  return (
    <div className='relative'>
      <select
        className={`appearance-none outline-0 py-2 px-4 border-none focus:outline-none text-[14px] cursor-pointer focus:ring-0 ring-0 ${isNoBuerFlow ? 'bg-white text-black' : 'bg-blue-darkest text-white'}`}
        value={language}
        onChange={handleLanguageChange}
      >
        <option value='en'>Eng</option>
      </select>
      <div className='pointer-events-none absolute inset-y-0 right-[-11px] flex items-center px-2'>
        {!isNoBuerFlow ? <ArrowDown className='h-2.5 w-2.5' /> : <ArrowDownBlack className='h-2.5 w-2.5' />}
      </div>
    </div>
  );
}
